
.label--page{ 
  z-index: 100;
  background-color:rgba(255,255,255,1);

  section{
    margin: 50px;
h1{
    font-size: 30px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
}




.tbl-header {
  position: relative;
  background-color: #f1f1f1;
  color: #000;

 padding: 0px 15px;



    tr {
      height: 80px !important;
 
    }

    th{
      // height: 100px !important;
      padding: 0px 25px;
    }
 
    table{
     width:100%;
    //  height: 100vh;
     table-layout: fixed;
     color: #000000;
     margin-bottom: 100px;
 
   }
   }


  .tbl-content{
    height: calc(100vh - 230px);
    margin-top: -100px !important;
    overflow-x:auto;
    margin-top: 0px;
    border: 1px solid rgba(255,255,255,0.3);
 
    table{
      width:100%;
      height: 100vh;
      table-layout: fixed;
      color: #000000;
      margin-bottom: 100px;


      tr {
        // height: 100px !important;
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        text-transform: uppercase;
        background-color: white;
     
     
        td{
          padding: 15px;
          text-align: left;
          vertical-align:middle;
          font-weight: 300;
          font-size: 16px;
          color: #000000;
          border-bottom: solid 1px rgba(34, 32, 32, 0.1);
        }
      }
     
    }
  
 
}
  
  /* demo styles */
  
  // @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
  // body{
  //   background: -webkit-linear-gradient(left, #25c481, #25b7c4);
  //   background: linear-gradient(to right, #25c481, #25b7c4);
  //   font-family: 'Roboto', sans-serif;
  // }

 
  


  
  /* for custom scrollbar for webkit browser*/
  
  ::-webkit-scrollbar {
      width: 6px;
  } 
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  } 
  ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }
}

button.add, button.delete, button.edit {
  padding: 5px 15px;
  margin-right: 5px;
  // border-radius: 12px;
  // background: #fff;
}
}
