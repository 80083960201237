
h1 { color:#089000;}
h3 { color: #089000;}
h4 { color: #089000;}

.libelium-box { border-radius: 10px; margin-bottom: 2%; background: #f1f1f1;}
.libelium-box:hover {box-shadow:#089000 -15px 0px 0px 0px; transition: 0.3s ease-in-out; }
.libelium-value { border-radius: 10px 0 0 10px; background: #089000  }
.libelium-info {padding-left: 25px;}
.teCentroj {padding: 0 !important; margin-left: -15px !important;}
.bg-main {background: rgba(0, 0, 0, 0) linear-gradient(rgb(255, 255, 255) 0%, rgb(183, 212, 54) 5%, rgb(60, 188, 160) 78%) repeat scroll 0% 0% !important;}
.btn-main {background: rgba(0, 0, 0, 0) linear-gradient(rgb(255, 255, 255) 0%, rgb(183, 212, 54) 5%, rgb(60, 188, 160) 78%) repeat scroll 0% 0% !important; border: none !important; margin-right: -15px !important;}
.bg-main-1 {background:#089000;} 
.text-main {color: #089000;}
.main-hover:hover {border-bottom: #FFF 4px solid; border-top: #FFF 4px solid; transition: 0.1s ease-in-out; }
.box-shadow-dn {box-shadow:#010101 -1px 8px 15px -10px; }
.height-box {height: 250px !important;}
.height-box-1 {height: 450px !important;}
.fixed-nav {position: fixed; top:0; left:0; right: 0; overflow: hidden;width: 100vw; z-index: 99999;}
.fixed-content {position: fixed; top:5% ; left:0; right: 0;width: 100vw; z-index: 999999;}
a {text-decoration: none; color: inherit;}
a:hover {text-decoration: none; color: inherit;}
.sensValue h1 {color: #fff; text-align: center; font-size: 62px;}
p.sensMeasure {color: #fff; text-align: center; font-size: 22px; margin-top:-35px;}
.sensInfo { line-height: 2.5em; font-weight: 400;}
.iotnav {background: rgba(0, 0, 0, 0) linear-gradient(rgb(255, 255, 255) 0%, rgb(183, 212, 54) 5%, rgb(60, 188, 160) 78%) repeat scroll 0% 0% !important;}
.header-img { min-width: 200px; width: 22vw; padding: 7px; }
.footer-img { min-width: 120px; width: 10vw; margin: auto !important;}
/* .abs-top { position: absolute; top:0; left:0; right:0;}
.abs-bottom { position: absolute; bottom:0; left:0; right:0;} */
.lh-min {line-height: 1em;}
.app-front {position: absolute; top: 100px !important; margin: auto !important; left:0 !important; right:0 !important; overflow-x: hidden;} 
.app-background {position:fixed ; width: 100% !important; height: 100%; margin: 0; padding: 0; top: 0; bottom: 0; background-position: 50% 50%;}
.aqi-info-header {width: 98%; border-radius: 20px !important; }
.airPolutantsMain { padding-left: 15px; margin-top: -0.7% !important;}
.airPolutantsMain h4 {padding-left: 11px;}
.aqiPolutants { width: 30% !important;margin-left:3% !important; margin-bottom:1% !important; background-color: #f1f1f1 !important; border-radius: 0 20px 20px 0; padding: 6px; border-left: #089000 5px solid;transition: ease-in-out .3s;}
.aqiPolutants:hover {border-left: #089000 10px solid;}
.aqiPolutants h2 {line-height: 0; margin-top: 10px; font-size: 25px; color: #089000;}
.aqiPolutants span { line-height:0; font-size: 14px;}
.aqiFace { border-radius: 30px 0 0 30px;  padding: 20px 0 !important; width: 100; transition: ease-in-out .3s;}
.aqiFace img { min-width: 110px !important; opacity: .5;}
.aqiInfo { padding : 20px; border-radius: 0 30px 30px 0 ;transition: ease-in-out .3s;}
.aqiInfo p { margin-top: -4%; }
.aqiInfoValue {padding-top : 20px; padding-bottom : 10px;}
.aqiInfoValue h4 { font-size: 48px;}
.weatherBox { width: 80% !important; font-size: 18px; border-radius: 20px;}
/* .aqiFace:hover { margin-right: 7px !important; opacity: .9; } */
.aqiFace img {margin: 0 auto; display: block;}
.lang{z-index: 100 !important;position: fixed; top: 2.1% ;right: 195px;}
/* .aqiInfo:hover {margin-left: 5px; opacity: .9;} */
.lang button{background-color: #fff;border: 0;}
.lang button.btn_active{border-bottom: 4px solid #089000;}
.lang button:hover{border-bottom: 4px solid #638b61;}
.lang button {padding: 10px 10px;border: none;background: #f1f1f1;}
.lib-navLinks {position: fixed;right: 10px !important;top: 100px;width: 30%;font-size: 22px;z-index: 9999;}
.lib-navLinks li:hover {padding-left: 10px;margin: 10px 0;list-style: none;transition: ease-in-out .5;}
.lib-navLinks li {background-color: #fff;} 
.lib-navLinks ul {list-style: none; padding: 0 !important; background-color: none; margin: 0;}
.hpGraph { height: 200px !important;}
/* .hpGraph canvas { height: 150px !important;} */
/* .menuBtnDiv { padding-left: calc(100% - 100px) !important; margin-top: -3.4%;} */
.menuBtnDiv { position: fixed !important; left: calc(100% - 120px) !important; margin-top: 0.5% !important; z-index: 990;}
span.scifiMode  { position: absolute ; vertical-align: middle !important; padding: 0px 15px; }
.autoRe {position: relative; font-size: 22px; margin-top: -5px !important;}
.autoReLabel { display: none; transition: .8s ease-in-out; font-size: 16px; vertical-align: middle !important; padding-bottom: 5px;}
.autoRe:hover .autoReLabel, .autoRe:focus .autoReLabel { transition: .8s ease-in-out; display: inline-block;}
.fbicon { position: absolute; right: -25%; top:-7px;}
.fbicon img {filter: grayscale(100%); border-radius: 15%; } 
.fbicon img:hover, .fbicon img:focus {filter: grayscale(0%);} 

@media (max-width: 990px){
  .lang { margin-top: -8px !important; z-index: 99999;}
  .aqiFace { border-radius: 50px;  padding: 5px; margin-bottom: 0; z-index: 2222;}
  .aqiInfo { padding-top:15px  !important; padding-bottom: 15px;  }
  .aqiInfoValue { padding-top:15px  !important; padding-bottom: 15px; }
  .aqi-info-header { width:100%; border-radius: 50px !important;}
  .app-front {position: absolute; top: 60px !important; margin: auto !important; left:0 !important; right:0 !important; overflow-x: hidden;} 
  .menuBtnDiv { left: calc(100% - 100px) !important; margin-top: 0.2% !important;}
  .lang{right: 20%;top: 1.7% !important;}
  /* .fbicon { position: absolute; right: -25%; top:-7px;} */
  #aqiDiv .row { border-radius: 50px;}
}

@media (max-width: 768px){
.aqi-info-header {width: 98%; border-radius: 15px !important;}
.aqiFace { border-radius:0px;  padding-top: 15px; padding-bottom: 15px;  z-index: 2222; margin-bottom: 0;}
.aqiFace img { min-width: 85px !important;  opacity: .5;}
.aqiInfo { border-radius:0px; font-size: 12px;}
.aqiInfoValue {font-size: 12px;}
.aqiInfoValue h4 {font-size: 32px;}
.weatherBox {width: 98% !important; margin-bottom: 15px; padding: 10px !important;}
.aqiInfo h4 { font-size: 18px;}
.lib-navLinks { position: fixed;right: 0px;left: 0 !important;top: 65px !important;width: 100% !important; z-index: 9999;transition: ease-in-out 1s;}
.menuBtnDiv { left: calc(100% - 80px) !important; margin-top: 0.2% !important;}
.airPolutantsMain { padding-right: 32px !important; padding-left: 18px !important; padding-top: 15px;}
.airPolutantsMain h4 {padding-bottom: 15px; padding-left: 10px;}
.aqiPolutants {width: 47% !important;}
.lang{right: 20%;top: 1.8% !important; z-index: 999999;}
.lang button{background-color: #fff;border: 0;}
.lang button.btn_active{border-bottom: 4px solid #089000;}
.lang button:hover, .lang button:focus{border-bottom: 4px solid #638b61;}
.lang button {padding: 10px 10px;border: none;background: #f1f1f1; z-index: 999999;}
/* .header-img  {min-width: 180px !important; } */

}


@media (max-width: 440px){
  .aqi-info-header {width: 98%; border-radius: 15px !important;}
  .aqiFace { border-radius:0px;  padding-top: 15px; padding-bottom: 15px;  z-index: 2222; margin-bottom: 0;}
  .aqiFace img { min-width: 85px !important;  opacity: .5;}
  .aqiInfo { border-radius:0px; font-size: 12px;}
  .aqiInfoValue {font-size: 12px;}
  .aqiInfoValue h4 {font-size: 32px;}
  .weatherBox {width: 98% !important; margin-bottom: 15px; padding: 10px !important;}
  .aqiInfo h4 { font-size: 18px;}
  .lib-navLinks { position: fixed;right: 0px;left: 0 !important;top: 65px !important;width: 100% !important; z-index: 9999;transition: ease-in-out 1s;}
  .menuBtnDiv { left: calc(100% - 80px) !important; margin-top: -1% !important;}
  .airPolutantsMain { padding-right: 32px !important; padding-left: 18px !important; padding-top: 15px;}
  .airPolutantsMain h4 {padding-bottom: 15px; padding-left: 10px;}
  .aqiPolutants {width: 47% !important;}
  .lang{right: 20%;top: 2% !important; z-index: 999999;}
  .lang button{background-color: #fff;border: 0;}
  .lang button.btn_active{border-bottom: 4px solid #089000;}
  .lang button:hover, .lang button:focus{border-bottom: 4px solid #638b61;}
  .lang button {padding: 10px 10px;border: none;background: #f1f1f1; z-index: 999999;}
  .header-img  {min-width: 180px !important; margin-left: -5% !important;}
  
  }



@media (max-width: 320px){
.aqiFace img { margin-left: -12% !important;}
.lang{right: 25%;top: 3.5% !important; z-index: 999999;}
.lang button {padding: 5px !important;}
/* .menuBtnDiv { left: calc(100% - 80px) !important; margin-top: -1% !important;} */
.header-img  {min-width: 160px !important; margin-left: -5% !important;}
}



.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 9em;
  height: 9em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
overflow: hidden;
  border-top: 1.1em solid rgba(25, 171, 39, 0.2);
  border-right: 1.1em solid rgba(25, 171, 39, 0.2);
  border-bottom: 1.1em solid rgba(25, 171, 39, 0.2);
  border-left: 1.1em solid #19AB27;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.rotating-css {
  animation: rotating 2s linear;
  animation-iteration-count: infinite;
}
/* Make the element rotate infinitely. */
/* 
Usage
    .myElement {
        animation: rotating 3s linear infinite;
    }
*/
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}